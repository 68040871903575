import React from 'react'
import styled, { css } from 'styled-components';
import { safeMultipleNewLineToBr } from '../utils/utils';

const RichTextDiv = styled.div`
  ${props => !props.className && css`
    font-size: 13px;
  `}
  div {
    min-height: 16px; 
  }
  p {
    min-height: 16px;
    font-size: 12px;
    margin: 0;
  }
  .frame-object{
    min-height: 0px;
  }
  ul {
    list-style-type: disc;
  }
  ul, ol {
    padding-inline-start: 25px;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }
  label {
    display: block;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }
  ${props => props.withMargins && css`
    margin: 0.5rem 0.5rem 2rem 0.5rem;
  `}
  ${props => props.className && props.className.indexOf('overview') >= 0 && css`
    font-size: 14px !important;
    p {
      min-height: 16px;
      font-size: 14px;
      margin: 0;
    }
    .frame-object{
      min-height: 0px;
    }
    table-content div{
      font-size: 14px;
    }
  `}
  
`

function fixAccessibleContent(content) {
  try {
      // Parse the HTML content safely
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, "text/html");

      // Fix all <img> elements (images)
      doc.querySelectorAll("img").forEach(img => {
          try {
              if (!img.hasAttribute("alt") || img.getAttribute("alt").trim() === "") {
                  img.setAttribute("alt", "Report content image");
              }
              if (!img.hasAttribute("role")) {
                  img.setAttribute("role", "img");
              }
              if (!img.hasAttribute("aria-hidden")) {
                  img.setAttribute("aria-hidden", "false");
              }
          } catch (innerError) {
              console.warn("Error processing image:", img.outerHTML, innerError);
          }
      });

      // Return the modified HTML as a string
      return doc.body.innerHTML;
  } catch (error) {
      console.error("Error processing HTML content:", error);
      return content; // Return original content if an error occurs
  }
}

export const RichTextDisplay = ({content, className, useSafeNewLine = false}) => <RichTextDiv
  className={className}
  dangerouslySetInnerHTML={{ __html: useSafeNewLine ? safeMultipleNewLineToBr(content) : fixAccessibleContent(content) }}
/>