import {callIfFunction, createPromiseQueue, getFetcherWithResponse} from '@startlibs/utils'
import {useToggle} from '@startlibs/core'
import _ from 'lodash/fp'
import {jwtGetFetcher, jwtPostFetcher} from '../../utils/authFetch'
import {DicomStudy} from "../../enums/RecordFormat";
import {getShortTokenUrl} from "./endpointUtils";
// import {getJwt} from "../../../../src/javascripts/hooks/useJwt";

export const downloadQueue = createPromiseQueue()

export const downloadFile = (launchURL) => {
  if (window.navigator && window.navigator.msSaveBlob) {
    window.location.href = launchURL
  } else {
    const link = document.createElement('a')
    link.href = launchURL
    link.style.visibility = "hidden"
    link.setAttribute("download", "")
    document.body.appendChild(link);
    link.click()
    link.remove()
  }
}

var getOrigin = function (href) {
  const [protocol, , host] = href.split("/")
  return protocol + "//" + host;
};

export const checkForDownload = (url) => new Promise((res, rej) => {
  let tries = 0;
  if (window.xdomain) {
    const origin = getOrigin(url)
    window.xdomain.slaves({
      [origin]: "/public/proxy.html"
    })
  }
  const check = () => getFetcherWithResponse(url, null, {method: 'HEAD'})
    .then(([, response]) => response.status === 200 ? res(url) : setTimeout(check, 1000))
    .catch(([req, response]) => {
      if (!response) {
        tries++;
        if (tries > 5) {
          rej(response)
        } else {
          setTimeout(check, 1000)
        }
      } else if (response.status === 404) {
        rej(response.status)
        console.log('missing file')
      } else if (response.status === 500) {
        rej(response.status)
        console.log('server error')
      } else {
        rej(response.status)
      }
    })
  check()
})


export const useDownload = (
  jwt, record,
  downloadLoadUrl = (record) =>
    jwtPostFetcher(callIfFunction(jwt))(`/api/downloadLink`,
      record.nonCompliantFiles
        ? {files: record.nonCompliantFiles.map(f => f.uid + "." + f.fileExtension)}
        : {studyUid: record.studyUID}
    ),
  filename
) => {

  const loading = useToggle()
  const started = useToggle()
  const downloadError = useToggle()

  const download = async () => {
    downloadError.close()
    loading.open()
    const {
      value: [unlock],
    } = await downloadQueue.next()
    try {
      const {url} = await callIfFunction(downloadLoadUrl, record, filename)
      const downloadUrl = await checkForDownload(callIfFunction(url))
      downloadFile(downloadUrl)
      started.open();
      started.closeAfter(1500);
    } catch (e) {
      downloadError.open()
    } finally {
      loading.close()
      unlock()
    }
  }

  return [download, loading.isOpen, started.isOpen, downloadError.isOpen]
}

export const useNewDownload = (
  jwt, records, downloadAction, filename, requestId, shortTokenUrl = (requestId) => {
    return requestId 
      ? jwtGetFetcher(callIfFunction(jwt))(`/api/shortDownloaderToken`,{requestId: requestId})
      : jwtGetFetcher(callIfFunction(jwt))(`/api/shortDownloaderToken`)}
) => {

  const loading = useToggle()
  const started = useToggle()
  const downloadError = useToggle()

  const download = async () => {
  
    downloadError.close()
    loading.open()
    const {
      value: [unlock],
    } = await downloadQueue.next()
    try {
      // jwtGetFetcher(callIfFunction(jwt))(`/api/shortDownloaderToken`)
      shortTokenUrl(requestId)
        .then((response) => {
          callIfFunction(downloadAction(response.jwt, records))
          started.open();
          started.closeAfter(1500);
        })
    } catch (e) {
      downloadError.open()
    } finally {
      // loading.close()
      setTimeout(() => {
        unlock()
        loading.close()
      },1500)
      // unlock()
    }
  }

  return [download, loading.isOpen, started.isOpen, downloadError.isOpen]
}

export const useViewStudy = (jwt, studies, viewerLoadUrl = (studies) => jwtGetFetcher(callIfFunction(jwt))(`/api/viewerLink?studyUids=${studies?.map(_.get("studyUID"))?.join("|")}`)) => {

  const loading = useToggle()

  const openViewer = () => {
    const wi = window.open('about:blank', '_blank')
    loading.wait(
      viewerLoadUrl(studies, jwt)
        .then(({viewerURL}) => {
          wi.location.href = viewerURL;
        })
        .catch(wi.close)
    )
  }

  return [openViewer, loading.isOpen]
}

// export const useViewNonDicom = (jwt, record, requestId, shortTokenUrl = (requestId) => jwtGetFetcher(callIfFunction(jwt))(`/api/shortDownloaderToken`,{requestId: requestId})) => {
const DEFAULT_DICOM_VIEWER_LOADER = (jwt) => (studies) => jwtGetFetcher(callIfFunction(jwt))(`/api/viewerLink?studyUids=${studies?.map(_.get("studyUID"))?.join("|")}`)
export const useViewRecord = (config, record, hideDownloadButton = false) => {
  return useViewRecords(config,record.recordFormat,[record], hideDownloadButton)
}
export const useViewRecords = (config, recordFormat, records, hideDownloadButton = false) => {
  const {
    appJwt,
    requestId,
    apiEndpoints,
  } = config
  if (recordFormat === DicomStudy) {
    const loading = useToggle()
    const viewerLoadUrl = apiEndpoints.dicomViewer || DEFAULT_DICOM_VIEWER_LOADER(appJwt)
    const openViewer = () => {
      const wi = window.open('about:blank', '_blank')
      loading.wait(
        viewerLoadUrl(records, appJwt)
          .then(({viewerURL}) => {
            wi.location.href = viewerURL;
          })
          .catch(wi.close)
      )
    }
    return [openViewer, loading.isOpen]
  }

  const loading = useToggle()
  const openViewer = () => {
    const shortTokenUrl = getShortTokenUrl(config)
    loading.wait(
      shortTokenUrl(requestId)
        .then((response) => {
          const wi = window.open('about:blank', '_blank')
          const finalUrl  = response.storageHost+`/view/record/${records[0].recordUID}?t=${response.jwt}${hideDownloadButton ? "&hideDownloadButton=true" : ""}`;
          wi.location.href = finalUrl;
          // wi.location.href = response.storageHost+`/view/record/${records[0].recordUID}?t=${response.jwt}&hideDownloadButton=true`;
        })
        // .catch(wi.close)
    )
  }
  return [openViewer, loading.isOpen]
}